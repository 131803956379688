@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoB.ttf") format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoH.ttf") format('truetype');
  font-weight: 1000;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoL.ttf") format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoM.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Black.ttf") format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: "GurmukhiMN";
  src: url("./fonts/GurmukhiMN.ttc") format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: "Questrial";
  src: url("./fonts/Questrial-Regular.ttf") format('truetype');
}
@font-face {
  font-family: "LINESeedSansKR";
  src: url("./fonts/LINESeedKR-Rg.ttf") format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: "LINESeedSansKR";
  src: url("./fonts/LINESeedKR-Th.ttf") format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: "LINESeedSansKR";
  src: url("./fonts/LINESeedKR-Bd.ttf") format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: "PP Monument Extended";
  src: url("./fonts/MonumentExtended-Regular.otf") format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("./fonts/NotoSansKR-Medium.otf") format('truetype');
  font-weight: 400;
}
/*
@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoEB.ttf") format('truetype');
  
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url("./fonts/AppleSDGothicNeoH.ttf") format('truetype');
} */

@media (orientation: landscape) {
  .mainBackground{
    position: fixed;
    width: auto;
    height: 88vh;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -46%);
  }
  .full-page-controls{
    width: 100%;
    height: 9.76vh;
    z-index: 3;
    position: fixed;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .lancdscape-header {
    position: relative;
  }
  .headerContent{
    font-family: "Questrial";
    font-size: 1.75vh;
    letter-spacing: 0.04em;
    border: 0;
    outline: 0;
  }
  div.headerContent :nth-child(1){
    justify-items: center;
    display: flex;
  }
  a.headerContent :nth-child(2){
    text-align: center;
  }
  a.headerContent :nth-child(3){
    text-align: center;
  }
  a.headerContent :nth-child(4){
    text-align: center;
  }
  a.headerContent :nth-child(5){
    text-align: center;
  }
  a.headerContent :nth-child(6){
    text-align: center;
  }
  
  button {
    background-color: #ffffff;
    color: #888A88;
    font-weight: 400;
  }
  
  button:disabled {
    color: #2d2E2d;
    font-weight: 1000;
  }  
  .S1box1 {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    margin-right: 5vh;
  }
  .S1box2 {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(40%);
  }
  .S1box3 {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(-20%);
  }
  .S1box4 {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(40%);
  }
  .S1box2After {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(-5%);
  }
  .S1box3After {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(5%);
  }
  .S1box4After {
    display: flex;
    flex-direction: column;
    padding: 1.22vh;
    margin-bottom: 5vh;
    transition: all ease 1s;
    transform: translateY(-5%);
  }
  .S1text1 {
    font-family: "LINESeedSansKR";
    font-size: 2.343vh;
    letter-spacing: -0.0625vh;
    color: #2d2E2d;
  }
  .S1text2 {
    font-family: "LINESeedSansKR";
    font-size: 5.468vh;
    font-weight: 700;
    letter-spacing: -0.125vh;
    color: #2d2E2d;
    margin-top: 1.56vh;
    margin-bottom: 3.25vh;
  }
  .S1text3 {
    font-family: "LINESeedSansKR";
    font-size: 1.757vh;
    color: #2d2E2d;
    letter-spacing: -0.046vh;
    line-height: 3.125vh;
  }
  .S1text4 {
    font-family: "LINESeedSansKR";
    font-size: 1.953vh;
    color: #2d2E2d;
    font-weight: 700;
    text-align: center;
    margin-top: 2.34vh;
    margin-bottom: 0.78vh;
  }
  .S1text5 {
    font-family: "LINESeedSansKR";
    font-size: 1.171vh;
    color: #2d2E2d;
    text-align: center;
    letter-spacing: -0.027vh;
    line-height: 1.757vh;
  }
  .S1img{
    width: 20.7vh;
    /* box-shadow: 5px 5px 10px 0px rgba(185, 204, 67, 0.75); */
  }
  .highlight{
    background: linear-gradient(to top, #E0F751 65%, transparent 65%);
  }
  .Slide2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .S2box1text1{
    font-family: "Questrial";
    font-size: 12.516vh;
    font-weight: 400;
    line-height: 12.89vh;
    color: #353735;
  }

  .S2box1img1{
    height: 7.81vh;
    transform-origin: 50% -10%;
    transform: translateY(-80%);
    -webkit-animation: pinwheel 1.5s ease infinite alternate;
    animation: pinwheel 1.5s ease infinite alternate;
  }

  @keyframes pinwheel {
    0%{transform:rotate(0deg) translateY(-60%);}
    100%{transform:rotate(-90deg) translateY(-60%);}
  }
  @-webkit-keyframes pinwheel {
    0%{-webkit-transform:rotate(0deg) translateY(-60%);}
    100%{-webkit-transform:rotate(-90deg) translateY(-60%);}
  }

  .S2box1img2{
    width: 20.7vh;
  }

  .S2box1text2{
    font-family: "LINESeedSansKR";
    font-size: 2.34vh;
    font-weight: 400;
    line-height: 3.9vh;
    margin-top: 6.25vh;
    color: #353735;
  }

  .S2apple {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-size: 2.2vh;
    width: 20.7vh;
    height: 6.25vh;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    border-radius: 6px;
    margin-top: 3.125vh;
  }
  .S2apple > img{
    width: 2.11vh;
    height: 2.47vh;
    margin-right: 1.6vh;
  }
  .S2google {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-size: 2.2vh;
    width: 20.7vh;
    height: 6.25vh;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    border-radius: 6px;
    margin-top: 3.125vh;
    margin-left: 2.34vh;
  }
  .S2google > img{
    width: 2.16vh;
    height: 2.34vh;
    margin-right: 1.4vh;
  } 
  /* .S2text1{
    font-family: "AppleSDGothicNeo";
    font-size: 6.25vh;
    font-weight: 800;
    line-height: 7vh;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 1.5vh;
  }
  .S2text2{
    font-family: "AppleSDGothicNeo";
    font-size: 2.34vh;
    font-weight: 400;
    line-height: 3vh;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 15vh;
  }
  
  .S2mainimg {
    display: block;
    width: 130vh;
    margin-bottom: 5vh;
  } */
  .Slide3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex-direction: column;
  }

  .S3boxtext1 {
    font-family: 'LINESeedSansKR';
    font-weight: 700;
    font-size: 6.25vh;
    letter-spacing: -0.125vh;
    margin-bottom: 1.95vh;
    color: #2D2E2D;
  }

  .S3boxtext2 {
    font-family: 'LINESeedSansKR';
    font-weight: 400;
    font-size: 2.34vh;
    letter-spacing: -0.046vh;
    margin-bottom: 2.92vh;
    line-height: 166.66%;
    text-align: center;
    color : #353735;
  }
  .S3boxtext3 {
    font-family: 'Questrial';
    font-weight: 400;
    font-size: 2.34vh;
    width: 32.226vh;
    height: 6.25vh;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    margin-bottom: 20vh;
    border-radius: 16px;
  }
  .S3boxtext3 > img{
    width: 2.34vh;
    height: 2.34vh;
    vertical-align: middle;
    margin-right: 1.95vh;
  } 

  
  .Slide5 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex-direction: column;
  }
  .S5text1{
    font-family: "LINESeedSansKR";
    font-size: 6.25vh;
    color: #2d2E2d;
    letter-spacing: -0.125vh;
    font-weight: 700;
    text-align: center;
  }
  .S5text2{
    font-family: "LINESeedSansKR";
    font-size: 2.34vh;
    color: #2d2E2d;
    letter-spacing: -0.046vh;
    line-height: 3.9vh;
    margin-top: 1.953vh;
    text-align: center;
  }
  .S5box {
    margin-top: 3.9vh;
    margin-bottom: 20vh;
  }
  .S5textbox {
    background-color: #FFFFFF;
    height: 7.03vh;
    width: 43.75vh;
    display: inline-flex;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #C4C7C4;
    margin-right:2.34vh;
  }
  .S5input {
    width:60%;
    vertical-align: middle;
    font-size: 2.34vh;
    font-family: "LINESeedSansKR";
    color: #2d2E2d;
    border: 0px;
    padding-left: 1vh;
    padding-right: 1vh;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  .S5box > input::placeholder{
    color: #C4C7C4;
  }
  .S5box > button {
    height: 7.03vh;
    width: 20.7vh;
    font-family: "LINESeedSansKR";
    font-size: 2.34vh; 
    font-weight: 700;
    border-radius: 16px;
    color:#E7FF54;
    background-color: #2D2E2D;
    border: 1px solid #2D2E2D;
  }
  .S5box > button:hover {
    background-color: #B9CC43;
    /* color: #FFFFFF; */
    border: 0px solid #2D2E2D;
  }
  .S5box > button:disabled{
    background-color: #A6A8A6;
    border: 0px solid #2D2E2D;
    color:#2D2E2D;
  }
  .emailSelectBox {
    display: block;
    font-size: 1.757vh;
    font-family: "LINESeedSansKR";
    color: #6A6B6A;
    background-color: #FFFFFF;
    text-align: center;
    border: 0px;
    width: 40%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  /* .S3donut{
    z-index: -2;
    position: absolute;
    height: 120vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.2);
    transition: all ease 2s;
    overflow: hidden;
  }
  
  .S3donutChanged{
    z-index: -2;
    position: absolute;
    height: 120vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 2s;
    overflow: hidden;
  }
  
  .S3bag {
    z-index: -1;
    position: absolute;
    height: 72.26vh;
    top: 50%;
    left: 50%;
    transform: translate(-200%, -130%) rotate(135deg);
    transition: all ease 2s;
  }
  
  .S3blanket {
    z-index: -1;
    position: absolute;
    height: 53.71vh;
    top: 50%;
    left: 50%;
    transform: translate(20%, -170%) rotate(15.83deg);
    transition: all ease 2s;
  }
  
  .S3tissue {
    z-index: -1;
    position: absolute;
    height: 20.21vh;
    top: 50%;
    left: 50%;
    transform: translate(-10%, 210%) rotate(-9.66deg);
    transition: all ease 2s;
  }
  
  .S3sticker {
    z-index: -1;
    position: absolute;
    height: 58.78vh;
    top: 50%;
    left: 50%;
    transform: translate(-200%, 50%) rotate(23.01deg);
    transition: all ease 2s;
  }
  
  .S3tumbler {
    z-index: -1;
    position: absolute;
    height: 45.89vh;
    top: 50%;
    left: 50%;
    transform: translate(150%, 65%) rotate(-22.31deg);
    transition: all ease 2s;
  }
  
  .S3bagChanged {
    z-index: -1;
    position: absolute;
    height: 72.26vh;
    top: 50%;
    left: 50%;
    transform: translate(-155%, -105%) rotate(135deg);
    transition: all ease 2s;
  }
  
  .S3blanketChanged {
    z-index: -1;
    position: absolute;
    height: 53.71vh;
    top: 50%;
    left: 50%;
    transform: translate(10%, -120%) rotate(15.83deg);
    transition: all ease 2s;
  }
  
  .S3tissueChanged {
    z-index: -1;
    position: absolute;
    height: 20.21vh;
    top: 50%;
    left: 50%;
    transform: translate(-25%, 120%) rotate(-9.66deg);
    transition: all ease 2s;
  }
  
  .S3stickerChanged {
    z-index: -1;
    position: absolute;
    height: 58.78vh;
    top: 50%;
    left: 50%;
    transform: translate(-163%, 12%) rotate(23.01deg);
    transition: all ease 2s;
  }
  
  .S3tumblerChanged {
    z-index: -1;
    position: absolute;
    height: 45.89vh;
    top: 50%;
    left: 50%;
    transform: translate(120%, 10%) rotate(-22.31deg);
    transition: all ease 2s;
  }
  
  
  } */
  
  .Slide4 {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .S4box1, .S4box2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107.5vh;
    justify-content: space-between;
  }
  .S4text1 {
    font-family: "LINESeedSansKR";
    font-size: 4.88vh;
    color: #2d2E2d;
    font-weight: 700;
    letter-spacing: -0.125vh;
  }

  .S4text2 {
    font-family: "LINESeedSansKR";
    font-size: 1.75vh;
    color: #2d2E2d;
    letter-spacing: -0.046vh;
    line-height: 3.9vh;
    margin-top: 3.125vh;
    margin-bottom: 5vh;
  }
  .S4img1{
    display: block;
    width: 39vh;
    transform: rotate(15deg) translate(-5vh, -5vh);
    margin-left: 10vh;
    -webkit-animation: mobingfly 1.3s ease infinite alternate;
    animation: mobingfly 1.3s ease infinite alternate;
  }

  @keyframes mobingfly {
    0%{transform:rotate(15deg) translate(-5vh, -5vh);}
    100%{transform:rotate(15deg) translate(-5vh, -7vh);}
  }
  @-webkit-keyframes mobingfly {
    0%{-webkit-transform:rotate(15deg) translate(-5vh, -5vh);}
    100%{-webkit-transform:rotate(15deg) translate(-5vh, -7vh);}
  }

  .S4box3{
    border-top: 0.3vh solid #2D2E2D;
    width: 21.5vh;
    margin-top: 3vh;
    opacity: 0.5;
  }
  .S4box3:hover > .S4img2 {
    visibility:visible;
  }
  .S4box3:hover {
    opacity: 1;
  }

  .S4img2{
    display: block;
    height: 0.6vh;
    width: 3.125vh;
    visibility: hidden;
  }
  .S4text3 {
    font-family: "PP Monument Extended";
    font-size: 2.34vh;
    color: #2d2E2d;
    font-weight: 700;
    font-style: italic;
    margin-top: 3.9vh;
    margin-bottom: 0.39vh;
  }
  .S4text4 {
    font-family: "LINESeedSansKR";
    font-size: 1.6vh;
    color: #2d2E2d;
    letter-spacing: -0.035vh;
  }
  /* .S4box0 {
    height: 5.46vh;
    background-color: #2d2d2d;
    border-radius: 28px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .S4box0 > img {
    vertical-align: middle;
    display: inline-flex;
    width: 2.34vh;
    margin-right: 0.5vh;
  }
  
  .S4box0 > div {
    vertical-align: middle;
    display: inline-flex;
    font-family: "AppleSDGothicNeo";
    font-size: 2.1vh;
    font-weight: 600;
    color: #FFFFFF;
  }
  
  .S4box1 {
    font-family: "AppleSDGothicNeo";
    font-size: 6.25vh;
    font-weight: 800;
    line-height: 7.2vh;
    color: #2d2E2d;
    margin-bottom: 2.5vh;
  }
  .S4box2, .S4box3 {
    font-family: "AppleSDGothicNeo";
    font-size: 2.4vh;
    font-weight: 400;
    line-height: 2.6vh;
    color: #2d2E2d;
    margin-bottom: 0.3vh;
  }
  .S4box4 {
    margin-top: 3vh;
    margin-bottom: 7vh;
  }
  
  .S4box4 > input {
    height: 7vh;
    width: 41.7vh;
    vertical-align: middle;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 400;
    line-height: 2.4vh;
    color: #2d2E2d;
    border-radius: 5px;
    border: 1px solid #353735;
    margin-right: 3vh;
    padding-left: 1vh;
    padding-right: 1vh;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
  }
  .S4box4 > input::placeholder{
    color: #A6A8A6;
  }
  .S4box4 > button {
    height: 7.2vh;
    width: 20.7vh;
    vertical-align: middle;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    line-height: 2.4vh;
    border-radius: 5px;
    color:#2D2E2D;
    background-color: #E7FF54;
    border: 1px solid #2D2E2D;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
  }
  .S4box4 > button:hover {
    background-color: #F8FFC6;
  }
  .S4box4 > button:active{
    box-shadow: inset 4px 4px 2px 0px #2D2E2D;
  }
  .S4box5 > .S4box5inbox {
    display: inline-flex;
    vertical-align: middle;
    height: 12.5vh;
    width: 24.21vh;
    border: 1px solid #2D2E2D;
    border-radius: 5px;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
    padding-left: 2vh;
    padding-right: 2vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #E7FF54;
    position: relative;
  }
  
  .S4box5 > .S4box5inbox > .title{
    height: 2vh;
    margin-bottom: 1vh;
    display: block;
  }
  .S4box5 > .S4box5inbox > .content{
    font-family: "AppleSDGothicNeo";
    font-size: 2.4vh;
    font-weight: 700;
    line-height: 3vh;
    color: #2d2E2d;
    text-align: center;
  }
  .S4box5 > .subtract {
    height: 3.5vh;
    text-align: center;
    vertical-align: middle;
    margin: 2.3vh;
  }
  
  .S4coupon {
    position: absolute;
    height: 4.5vh;
    top:0%;
    right: 0%;
    transform: rotate(-15deg) translate(3vh, 12vh);
  }
  
  .S4box6 {
    margin-top: 10vh;
  }
  
  .S4box6 > .button1 {
    height: 7.2vh;
    width: 20.7vh;
    vertical-align: middle;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    line-height: 2.4vh;
    border-radius: 5px;
    color:#2D2E2D;
    background-color: #E7FF54;
    border: 1px solid #2D2E2D;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
    margin-right: 1.5vh;
  }
  
  .S4box6 > .button1:hover {
    background-color: #F8FFC6;
  }
  .S4box6 > .button1:active{
    box-shadow: inset 4px 4px 2px 0px #2D2E2D;
  }
  
  .S4box6 > .button2 {
    height: 7.2vh;
    width: 20.7vh;
    vertical-align: middle;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    line-height: 2.4vh;
    border-radius: 5px;
    color:#2D2E2D;
    background-color: #FFFFFF;
    border: 1px solid #2D2E2D;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
    margin-left: 1.5vh;
  }
  
  .S4box6 > .button2:hover {
    background-color: #ebebeb;
  }
  .S4box6 > .button2:active{
    box-shadow: inset 4px 4px 2px 0px #2D2E2D;
  }
  
  .Slide4Half {
    background: #FFFFFF;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  
  .S4Hbox1 {
    width: 112.89vh;
    background-color: #F8FFC6;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .S4Hbox1textbox {
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 400;
    line-height: 3.5vh;
    transform: translateX(-5vh);
    margin-top: 4vh;
    color: #2D2E2D;
  }
  
  .S4Hbox1textbox > li > strong {
    font-weight: 800;
  }
  
  .S4Hbox2 {
    width: 112.89vh;
    display: flex;
    margin-top: 2.5vh;
  }
  
  .S4Hbox2First {
    flex-grow: 4;
    flex-basis: 0;
    background-color: #F8FFC6;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1.25vh;
  }
  
  .S4Hbox2Second {
    flex-grow: 3;
    flex-basis: 0;
    background-color: #F8FFC6;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1.25vh;
  }
  
  .S4Hbox2text {
    text-align: center;
    color: #2D2E2D;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    margin-bottom: 2.5vh;
  }
  
  .S4Hbox3 {
    width: 112.89vh;
    color: #A6A8A6;
    font-family: "AppleSDGothicNeo";
    font-size: 1.56vh;
    font-weight: 300;
    line-height: 2.35vh;
    margin-top: 1.5vh;
  } */
  
  .Slide5 {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .S5box1 {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-right: 15vh;
  }
  
  .S5box1inbox1{
    margin-right: 1vh;
  }
  
  .S5box1inbox2{
    margin-top: 10vh;
  }
  
  .S5box1intextbox1, .S5box1intextbox2, .S5box1intextbox3, .S5box1intextbox5
  {
    margin: 1.5vh 0;
  }
  
  .S5box1intextbox4{
    margin-top: 1.5vh;
    margin-bottom: 4vh;
  }
  
  .S5box1 > img {
    height: 100vh;
    display: block;
  }
  
  .S5box1text1{
    font-family: "AppleSDGothicNeo";
    font-size: 2.34vh;
    font-weight: 700;
    color:#2D2E2D;
  }
  
  .S5box1text2{
    display: inline-block;
    font-family: "AppleSDGothicNeo";
    font-size: 1.757vh;
    font-weight: 400;
    line-height: 2.14vh;
    color:#A6A8A6;
    margin: 0 2vh;
  }
  
  .S5box1text2 > strong {
    opacity: 0;
  }
  
  .S5box1text3{
    display: inline-block;
    font-family: "AppleSDGothicNeo";
    font-size: 1.757vh;
    font-weight: 400;
    line-height: 2.14vh;
    color:#2D2E2D;
  }
  
  .S5box2 {
    width: 66.796vh;
    height: 63.769vh;
    background: #E7FF54;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5vh;
  }
  
  .S5box2 > img {
    width: 62.89vh;
    display: block;
  }
  
  .S5box2text1 {
    font-family: "AppleSDGothicNeo";
    font-size: 6.25vh;
    font-weight: 700;
    line-height: 7.51vh;
    color:#2D2E2D;
    float: left;
    width: 62.89vh;
    margin-top: 1.8vh;
  }
  
  .S5box2text2 {
    margin-top: 1.8vh;
    font-family: "AppleSDGothicNeo";
    font-size: 2.34vh;
    font-weight: 400;
    line-height: 2.83vh;
    color:#2D2E2D;
    float: left;
    width: 62.89vh;
  }
  
  .Slide6 {
    background: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    z-index: 2;
  }
  
  .S6text1{
    position: relative;
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25vh;
    transition: all ease 4s;
    opacity: 1;
    transform: translateY(9.5vh);
  }
  
  .S6text1H{
    position: relative;
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25vh;
    opacity: 0;
    visibility: hidden;
    transition: all ease 4s;
  }
  
  .S6text1Changed {
    position: relative;
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25vh;
    transition: all ease 4s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(9.5vh);
  }
  
  .S6text1HChanged {
    position: relative;
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25vh;
    opacity: 1;
    transition: all ease 4s;
  }
  
  .S6text2, .S6text3{
    font-family: "AppleSDGothicNeo";
    font-size: 2.34vh;
    font-weight: 400;
    line-height: 2.83vh;
    color:#2D2E2D;
    margin-bottom: 0.3vh;
  }
  
  .S6box1{
    margin-top: 4vh;
    margin-bottom: 17vh;
  }
  
  .S6text4 {
    width: 26vh;
    height: 6.5vh;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    line-height: 2.4vh;
    color: #2d2E2d;
    background-color: #E7FF54;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2D2E2D;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
    border-radius: 5px;
    margin-right: 1.3vh;
    vertical-align: middle;
    text-decoration-line: none;
  }
  
  .S6text4:hover{
    background-color: #F8FFC6;
  }
  
  .S6text5 {
    width: 32.22vh;
    height: 6.5vh;
    font-family: "AppleSDGothicNeo";
    font-size: 2.3vh;
    font-weight: 700;
    line-height: 2.4vh;
    color: #2d2E2d;
    background-color: #FFFFFF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2D2E2D;
    box-shadow: 4px 4px 2px 0px #2D2E2D;
    border-radius: 5px;
    margin-left: 1.3vh;
    vertical-align: middle;
  }
  
  .S6text6 {
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-weight: 400;
    font-size: 3.515vh;
    color: #2d2d2d;
    line-height: 3.5vh;
  }
  
  .S6text7 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 1.17vh;
    margin-bottom: 2vh;
    color: #2d2d2d;
  }
  
  .S5text8 {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 400;
    font-size: 1.757vh;
    color: #2d2d2d;
    line-height: 3vh;
  }
  .footer1 {
    position: fixed;
    bottom: 0%;
    width: 100%;
    height: 20%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: all ease 1s;
  }
  .footerbox1{
    display: flex;
    flex-direction: column;
  }
  .footertext1{
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-size: 2.34vh;
    color: #2d2d2d;
    letter-spacing: -0.046vh;
  }
  .footertext2{
    color: #2D2E2D;
    font-size: 1.757vh;
    font-family: "LINESeedSansKR";
    letter-spacing: -0.035vh;
  }
  .footertext3{
    color: #2D2E2D;
    font-size: 1.367vh;
    font-family: "LINESeedSansKR";
    margin-top: 2.5vh;
  }
  .footerbox2{
    display: flex;
    flex-direction: column;
    margin-left: 3.125vh;
  }
  .footertext4{
    color: #4B4C4B;
    font-size: 1.367vh;
    font-family: "LINESeedSansKR";
    letter-spacing: -0.0273vh;
    margin: 0.29vh;
    width: 70vh;
  }
  .footerbox3{
    display: flex;
    flex-direction: column;
  }
  .footertext5{
    color: #4B4C4B;
    font-size: 1.367vh;
    font-family: "LINESeedSansKR";
    letter-spacing: -0.0273vh;
    text-align: right;
    margin: 0.29vh;
  }
  .footertext5 > strong{
    color: #2D2E2D;
  }
  .footer2 {
    position: fixed;
    bottom: 2%;
    left: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    z-index: 3;
    align-items: center;
    margin-bottom: 4vh;
    transition: all ease 1s;
  }
  
  .footer2 > div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    line-height: 3vh;
    font-size: 1.56vh;
    color: #2d2d2d;
  }
  
  .footer2 > img {
    display: block;
    height: 2.14vh;
    width: 4.88vh;
  }

  .Slide1 {
    position: relative;
    overflow: hidden;
    z-index: 2;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .main-portrait {
    display: none;
  }

  /* .rectanglebefore{
    display: block;
    position: absolute;
    height: 100vh;
    z-index: -1;
    transform: translateX(140%);
    transition: all ease 2s;
  }

  .rectangleafter{
    display: block;
    position: absolute;
    height: 100vh;
    z-index: -1;
    transform: translateX(20%);
    transition: all ease 2s;
  } */

}

@media (orientation: portrait) {
  .main-landscape {
    display: none;
  }
  .lancdscape-header {
    display: none;
  }
  .Slide1 {
    display: none;
    height: 0%;
  }
  .Slide2 {
    display: none;
    height: 0%;
  }
  .Slide3 {
    display: none;
  }
  .Slide4 {
    display: none;
  }
  .Slide4Half {
    display: none;
  }
  .Slide5 {
    display: none;
  }
  .Slide6 {
    display: none;
  }
  .full-page-controls{
    display: none;
  }
  .headerContent{
    display: none;
  }
  div.headerContent :nth-child(1){
    display: none;
  }
  a.headerContent :nth-child(2){
    display: none;
  }
  a.headerContent :nth-child(3){
    display: none;
  }
  a.headerContent :nth-child(4){
    display: none;
  }
  a.headerContent :nth-child(5){
    display: none;
  }
  a.headerContent :nth-child(6){
    display: none;
  }
  .full-page-controls > button {
    display: none;
  }
  
  .full-page-controls > button:disabled {
    display: none;
  }

  .footer2 {
    display: none;
  }
  .header {
    overflow: hidden;
  }

  .header-wrapper{
    width: 100%;
    height: 15.38vw;
    z-index: 3;
    position: fixed;
    top: 0%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .header-wrapper > a {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 4vw;
  }

  .header-wrapper > a > img {
    width: 19.74vw;
  }

  .header-wrapper > div {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 4vw;
  }

  .header-wrapper > div >img {
    display: block;
    width: 10.25vw;
  }

  .show-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    z-index: 4;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 47.94vw;
    padding: 0 4.1vw;
    transition: all ease 1s;
  }
  .menulist1 , .menulist2, .menulist3, .menulist4, .menulist5{
    height:15.38vw;
    font-family: "Questrial";
    font-size: 4.615vw;
    font-weight: 700;
    line-height: 5.64vw;
    color: #2D2E2D;
    align-items: center;
    display: flex;
  }

  .menulist0 {
    height:15.38vw;
    align-items: center;
    display: flex;
    justify-content: right;
  }

  .show-menu > div > img {
    width: 10.25vw;
  }

  .hide-menu{
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    z-index: 4;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 47.94vw;
    padding: 0 4.1vw;
    transform: translateX(100%);
    transition: all ease 1s;
  }

  body {
    margin-top: 10vw;
  }
  .mainBackground{
    position: fixed;
    width: 150vw;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -46%);
    z-index: -1;
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    padding-top: 20vw;
  }

  .S2box1{
    width: 91.79vw;
    margin-bottom: 20vw;
  }

  .S2box1text2{
    font-family: "LINESeedSansKR";
    font-size: 3.58vw;
    font-weight: 400;
    line-height: 160%;
    margin-top: 3.8vw;
    color: #2D2E2D;
    letter-spacing: -0.071vw;
  }

  .S2box1text1{
    font-family: "Questrial";
    font-size: 9.23vw;
    font-weight: 400;
    line-height: normal;
    color: #2D2E2D;
  }

  .S2box1img1{
    height: 6.33vw;
    transform-origin: 20% 0%;
    transform: translate(-30%,-50%);
    -webkit-animation: pinwheel 1.5s ease infinite alternate;
    animation: pinwheel 1.5s ease infinite alternate;
  }

  @keyframes pinwheel {
    0%{transform:rotate(0deg) translate(-30%,-50%);}
    100%{transform:rotate(-90deg) translate(-30%,-50%);}
  }
  @-webkit-keyframes pinwheel {
    0%{-webkit-transform:rotate(0deg) translate(-30%,-50%);}
    100%{-webkit-transform:rotate(-90deg) translate(-30%,-50%);}
  }

  .S2box1img2{
    width: 15.3vw;
  }
  .S2box1text3 {
    font-family: 'LINESeedSansKR';
    font-weight: 700;
    font-size: 4.6vw;
    width: 43.8vw;
    height: 12.8vw;
    background-color: #2D2E2D;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    margin-bottom: 20vw;
    margin-top: 23vw;
    border-radius: 10px;
  }

  .S2img1{
    display: block;
    width: 51.28vw;
    transform: rotate(15deg) translateY(4vw);
    -webkit-animation: mobingfly 1.3s ease infinite alternate;
    animation: mobingfly 1.3s ease infinite alternate;
    margin-bottom: 10vw;
  }

  @keyframes mobingfly {
    0%{transform:rotate(15deg) translateY(4vw)}
    100%{transform:rotate(15deg)}
  }
  @-webkit-keyframes mobingfly {
    0%{-webkit-transform:rotate(15deg) translateY(4vw)}
    100%{-webkit-transform:rotate(15deg)}
  }

  .section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 150vw;
    position: relative;
    align-items: center;
    padding-top: 30vw;
  }

  .S3boxtext1 {
    font-family: 'LINESeedSansKR';
    font-weight: 700;
    font-size: 9.23vw;
    letter-spacing: -0.184vw;
    margin-bottom: 3.07vw;
    text-align: center;
  }

  .S3boxtext2 {
    font-family: 'LINESeedSansKR';
    font-weight: 400;
    font-size: 3.58vw;
    letter-spacing: -0.0717vw;
    line-height: 160%;
    margin-bottom: 13.07vw;
    text-align: center;
  }
  .S3boxtext3 {
    font-family: 'Questrial';
    font-weight: 400;
    font-size: 4.6vw;
    width: 67.94vw;
    height: 13.15vw;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    margin-bottom: 16.67vw;
    border-radius: 12.2px;
  }
  .S3boxtext3 > img{
    width: 4.9vw;
    height: 4.9vw;
    vertical-align: middle;
    margin-right: 3vw;
  }
  .S3apple {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-size: 4.6vw;
    width: 43.8vw;
    height: 13.15vw;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    margin-bottom: 20vw;
    margin-top: 5vw;
    border-radius: 14px;
    margin-right: 2vw;
  }
  .S3apple > img{
    width: 4.9vw;
    height: 5.3vw;
    margin-right: 3vw;
  }
  .S3google {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-size: 4.6vw;
    width: 43.8vw;
    height: 13.15vw;
    background-color: #2D2E2D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration-line: none;
    color: #E7FF54;
    margin-bottom: 20vw;
    margin-top: 5vw;
    border-radius: 14px;
    margin-left: 2vw;
  }
  .S3google > img{
    width: 4.9vw;
    height: 5.1vw;
    margin-right: 3vw;
  } 
  .section3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    margin-top: 25vw;
  } 

  .S1box {
    display: flex;
    flex-direction: column;
    padding: 2.5vw;
    width: 90.7vw;
    margin-bottom: 4vw;
  }
  .S1text1 {
    font-family: "LINESeedSansKR";
    font-size: 4.615vw;
    letter-spacing: -0.1vw;
    color: #2d2E2d;
  }
  .S1text2 {
    font-family: "LINESeedSansKR";
    font-size: 9.23vw;
    font-weight: 700;
    letter-spacing: -0.225vw;
    color: #2d2E2d;
    margin-top: 3.07vw;
    margin-bottom: 5vw;
  }
  .S1text3 {
    font-family: "LINESeedSansKR";
    font-size: 3.58vw;
    color: #2d2E2d;
    letter-spacing: -0.071vw;
    line-height: 157%;
    text-align: right;
  }
  
  .highlight{
    background: linear-gradient(to top, #E0F751 65%, transparent 65%);
  }

  .section4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-top: 30vw;
    padding-top: 10vw;
    margin-bottom: 30vw;
  }

  .S4box1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20vw;
  }
  .S4text1 {
    font-family: "LINESeedSansKR";
    font-size: 9.23vw;
    color: #2d2E2d;
    font-weight: 700;
    letter-spacing: -0.184vw;
  }

  .S4text2 {
    font-family: "LINESeedSansKR";
    font-size: 3.58vw;
    color: #2d2E2d;
    letter-spacing: -0.071vw;
    line-height: 5.8vw;
    margin-top: 3.8vw;
  }
  
  .S4box2{
    width: 91.8vw;
    display: flex;
    overflow: scroll;
    border-right: solid 4.1vw transparent;
    border-left: solid 4.1vw transparent;
  }
  .S4box3{
    border-top: 0.7vw solid #2D2E2D;
    width: 54.3vw;
    opacity: 0.5;
    flex-shrink: 0;
  }
  .S4box3:hover > .S4img2 {
    visibility:visible;
  }
  .S4box3:hover {
    opacity: 1;
  }

  .S4img2{
    display: block;
    height: 1.5vw;
    width: 7vw;
    visibility: hidden;
  }
  .S4text3 {
    font-family: "PP Monument Extended";
    font-size: 6.15vw;
    color: #2d2E2d;
    font-weight: 800;
    font-style: italic;
    margin-top: 9.5vw;
    margin-bottom: 1vw;
  }
  .S4text4 {
    font-family: "LINESeedSansKR";
    font-size: 4.61vw;
    color: #2d2E2d;
    letter-spacing: -0.092vw;
    padding-bottom: 10vw;
  }

  .section5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 170vw;
    position: relative;
  }

  .S5text1{
    font-family: "LINESeedSansKR";
    font-size: 9.23vw;
    color: #2d2E2d;
    letter-spacing: -0.184vw;
    font-weight: 700;
    text-align: center;
  }
  .S5text2{
    font-family: "LINESeedSansKR";
    font-size: 3.58vw;
    color: #2d2E2d;
    letter-spacing: -0.0717vw;
    line-height: 160%;
    margin-top: 3.84vw;
    text-align: center;
    width: 92vw;
  }
  .S5box {
    margin-top: 10vw;
    margin-bottom: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .S5textbox {
    background-color: #FFFFFF;
    height: 12.8vw;
    width: 91.79vw;
    display: inline-flex;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #C4C7C4;
  }
  .S5input {
    width:60%;
    vertical-align: middle;
    font-size: 4.61vw;
    font-family: "LINESeedSansKR";
    color: #2d2E2d;
    border: 0px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  .S5box > input::placeholder{
    color: #C4C7C4;
  }
  .S5box > button {
    margin-top: 6.15vw;
    padding-top: 0.7vw;
    height: 12.82vw;
    width: 43.84vw;
    font-family: "LINESeedSansKR";
    font-size: 4.61vw; 
    font-weight: 700;
    border-radius: 16px;
    color:#E7FF54;
    background-color: #2D2E2D;
    border: 1px solid #2D2E2D;
  }
  .S5box > button:hover {
    background-color: #B9CC43;
    /* color: #FFFFFF; */
    border: 0px solid #2D2E2D;
  }
  .S5box > button:disabled{
    background-color: #A6A8A6;
    border: 0px solid #2D2E2D;
    color:#2D2E2D;
  }
  .emailSelectBox {
    display: block;
    font-size: 3.58vw;
    font-family: "LINESeedSansKR";
    color: #6A6B6A;
    background-color: #FFFFFF;
    text-align: center;
    border: 0px;
    width: 40%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  div.homepage > div {
    position: absolute;
    opacity: 0;
    z-index: -3;
    display: none;
    height: 0px;
  }

  .main-portrait {
    scroll-behavior: smooth;
  }
  
  .footer1On {
    position: fixed;
    bottom: 0%;
    width: 100%;
    height: 25%;
    background-color: #FFFFFF;
    display: flex;
    align-items: left;
    justify-content: center;
    padding-left: 14vw;
    z-index: 3;
    transition: all ease 2s;
    flex-direction: column;
  }
  .footer1Off{
    opacity: 0;
    position: fixed;
    bottom: 0%;
    width: 100%;
    height: 25%;
    background-color: #FFFFFF;
    display: flex;
    align-items: left;
    justify-content: center;
    z-index: 3;
    transition: all ease 2s;
    padding-left: 14vw;
    flex-direction: column;
  }
  .footertext1{
    font-family: 'GurmukhiMN';
    font-style: normal;
    font-size: 6.15vw;
    color: #2d2d2d;
    letter-spacing: -0.123vw;
    transition: all ease 2s;
  }
  .footertext2{
    color: #2D2E2D;
    font-size: 4.615vw;
    font-family: "LINESeedSansKR";
    letter-spacing: -0.09vw;
    transition: all ease 2s;
  }
  .footertext3{
    color: #2D2E2D;
    font-size: 3.58vw;
    font-family: "LINESeedSansKR";
    margin-top: 8vw;
    transition: all ease 2s;
  }
  .footer2On {
    position: fixed;
    bottom: 2%;
    left: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    z-index: 3;
    align-items: center;
    margin-bottom: 8vw;
    transition: all ease 2s;
  }
  .footer2Off{
    opacity: 0;
    position: fixed;
    bottom: 2%;
    left: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    z-index: 3;
    align-items: center;
    margin-bottom: 8vw;
    transition: all ease 2s;
  }
  
  .footer2On > div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 3.07vw;
    letter-spacing: 0.307vw;
    color: #2d2d2d;
    margin-bottom: 1.34vw;
    transition: all ease 2s;
  }
  
  .footer2On > img {
    display: block;
    height: 4.61vw;
    width: 9.615vw;
    transition: all ease 2s;
  }
  .footer2Off > div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 3.07vw;
    letter-spacing: 0.307vw;
    color: #2d2d2d;
    margin-bottom: 1.34vw;
    transition: all ease 2s;
  }
  
  .footer2Off > img {
    display: block;
    height: 4.61vw;
    width: 9.615vw;
    transition: all ease 2s;
  }
}


