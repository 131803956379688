@media (orientation: landscape) {
    .slider-track{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 62%;
        left: 50%;
        z-index: 1;
        /* transition: -webkit-transform 500ms ease 0s; */
        /* transition: transform 500ms ease 0s; */
    }

    #slider-item{
        position: relative;
        flex-shrink: 0;
        height: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #slider-item > img {
        display: block;
        justify-content: center;
        align-items: center;
    }

    /* .slider-item5, .slider-item1{
        -webkit-filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        opacity: 0.4; 
    }

    .slider-item4, .slider-item2{
        -webkit-filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        opacity: 0.8;
    }

    .slider-item3{
        -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    } */

    .slider-item0{
        visibility: hidden;
    }

    .item0 {
        width: 20.7vh;
        margin: 1.5vh;
    }

    .item1 {
        width: 20.7vh;
        margin: 1.5vh;
    }

    .item2 {
        width: 20.7vh;
        margin: 1.5vh;
    }

    .item3 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item4 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item5 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item6 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item5 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item6 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item7 {
        width: 20.7vh;
        margin: 1.5vh;
    }
    .item8 {
        width: 20.7vh;
        margin: 1.5vh;
    }
}
@media (orientation: portrait){
    .slider-track{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 65%;
        left: 50%;
        z-index: 1;
        /* transition: -webkit-transform 500ms ease 0s; */
        /* transition: transform 500ms ease 0s; */
    }

    #slider-item{
        position: relative;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #slider-item > img {
        display: block;
        justify-content: center;
        align-items: center;
    }

    /* .slider-item5, .slider-item1{
        -webkit-filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        opacity: 0.4; 
    }

    .slider-item4, .slider-item2{
        -webkit-filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        filter: invert(81%) sepia(31%) saturate(780%) hue-rotate(20deg) brightness(101%) contrast(104%);
        opacity: 0.8;
    }

    .slider-item3{
        -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    } */

    .slider-item0{
        visibility: hidden;
    }

    .item0 {
        width: 27.17vw;
        margin: 1.5vw;
    }

    .item1 {
        width: 27.17vw;
        margin: 1.5vw;
    }

    .item2 {
        width: 27.17vw;
        margin: 1.5vw;
    }

    .item3 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item4 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item5 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item6 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item5 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item6 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item7 {
        width: 27.17vw;
        margin: 1.5vw;
    }
    .item8 {
        width: 27.17vw;
        margin: 1.5vw;
    }
}
    